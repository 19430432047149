import axios from 'axios';

export async function fetchItems() {
    return (await axios.get('/items.json'))?.data
}

export async function fetchCategories() {
    return (await axios.get('/categories.json'))?.data
}

export async function fetchBrands() {
    return (await axios.get('/brands.json'))?.data
}