import React from 'react';
import {Outlet} from 'react-router-dom';
import {ItemContextProvider} from "../contexts/ItemContext";


export default function FullScreenLayout() {

    return (
        <ItemContextProvider>
            <div className="full-screen-layout"><Outlet/></div>
        </ItemContextProvider>
    )
}