import React, { createContext } from "react";
import * as itemService from "../services/items";

const initialValue = {
    items: [],
    brands: [],
    categories: {},
    selectedItem: null,
    selectedCategory: "전체",
    selectedBrand: "전체"
}
const ItemContext = createContext({});
export default ItemContext;

export class ItemContextProvider extends React.Component {
    constructor(props) {
        super(props);
        const createUpdater = (key) => {
            return (value) => {
                this.setState({
                    actions: this.state?.actions,
                    store: {
                        ...this.state?.store,
                        [key]: value
                    }
                })
            }
        }

        this.state = {
            store: initialValue,
            actions: Object.keys(initialValue).reduce(
                (actions, key) => ({
                    ...actions,
                    [`set${key.charAt(0).toUpperCase() + key.slice(1)}`]: createUpdater(key)
                }),
                {
                    fetchItems: async () => {
                        const items = await itemService.fetchItems();
                        this.state.actions.setItems(items);
                        if (items.length > 0) {
                            this.state.actions.setSelectedItem(items[0])
                        }
                    },
                    fetchCategories: async () => {
                        const categories = await itemService.fetchCategories();
                        this.state.actions.setCategories(categories);
                    },
                    fetchBrands: async () => {
                        const brands = await itemService.fetchBrands();
                        this.state.actions.setBrands(brands);
                    }
                }
            )
        };
    }

    render() {
        return <ItemContext.Provider value={this.state}>
            {this.props.children}
        </ItemContext.Provider>
    }
}