import React from 'react';
import {BrowserRouter, useRoutes} from 'react-router-dom';
import FullScreenLayout from "./layouts/FullScreenLayout";
import DemoView from "./views/DemoView";
import SessionContext, {SessionContextProvider} from "./contexts/SessionContext";
import LoginView from "./views/LoginView";


const AppRouter = () => {
    const sessionContext = React.useContext(SessionContext);
    const session = sessionContext?.store?.session;

    const routes = useRoutes([
        {
            path: '/',
            element: <FullScreenLayout />,
            children: [
                { path: '/', element: <DemoView /> },
            ]
        }
    ])
    const needLogin = useRoutes([
        {
            path: '/',
            element: <FullScreenLayout />,
            children: [
                { path: '/', element: <LoginView /> },
            ]
        }
    ])

    return (session === null)? needLogin : routes;
}

const Router = () => {
    return (
        <BrowserRouter>
            <SessionContextProvider>
                <AppRouter />
            </SessionContextProvider>
        </BrowserRouter>
    )
}

export default Router;