import React from 'react';
import {Container, Row, Col, Form, Button} from "react-bootstrap";
import SessionContext from "../contexts/SessionContext";

export default function LoginView() {
    const sessionContext = React.useContext(SessionContext);
    const [pw, setPw] = React.useState("");

    return <Container>
        <Row>
            <Col>
                <Form>
                    <Form.Group>
                        <Form.Control
                            type="password"
                            placeholder="PW"
                            value={pw}
                            onChange={(e) => setPw(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Button onClick={() => {
                            if (pw === 'sprtjtm') {
                                sessionContext.actions.setSession("temp session")
                            }
                        }}>
                            Check
                        </Button>
                    </Form.Group>
                </Form>
            </Col>
        </Row>
    </Container>
}