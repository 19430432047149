import React from 'react';
import './App.css';
import Routes from "./routes";
import 'bootstrap/dist/css/bootstrap.min.css';

const App:React.FC = () => {
  return (
    <div className="App">
      <Routes/>
    </div>
  );
}

export default App;
