import React from "react"
import "@google/model-viewer"

export default function ARViewer({src, ...props}) {
    return (
        <model-viewer
            src={src}
            style={{width: "100%", maxHeight: "360px", minHeight: "240px"}}
            shadow-intensity="1"
            ar
            ar-modes="webxr scene-viewer quick-look"
            disable-zoom
            camera-controls
        />
    )
}