import React from 'react';
import {
    Badge,
    ListGroup
} from "react-bootstrap";
import ItemContext from "../contexts/ItemContext";


function Item({item}) {
    const itemContext = React.useContext(ItemContext);
    const {selectedItem} = itemContext.store;
    const isSelected = React.useMemo(() => item.id === selectedItem?.id, [item, selectedItem])

    return (
        <ListGroup.Item
            onClick={() => itemContext.actions.setSelectedItem(item)}
            active={isSelected}
        >
            <div>
                <Badge bg="secondary">
                    {item?.seller?.brand}
                </Badge>
                <Badge bg="secondary">
                    {item?.seller?.manufacturer}
                </Badge>
                {
                    item?.categories?.map((cate, idx) => <Badge key={idx} bg="dark">{cate}</Badge>)
                }
            </div>
            <div>
                <strong>{item?.name}</strong>
            </div>
            <div>
                <small>{item?.option_name}</small>
            </div>
            <div>
                {
                    Object.entries(item?.markets)
                        ?.map(([key, value], idx) => (
                            value &&
                            <a key={idx} href={value} target="_blank">
                                <Badge bg="success">{key}</Badge>
                            </a>
                        ))
                }
            </div>
        </ListGroup.Item>
    )
}


export default function ItemList() {
    const itemContext = React.useContext(ItemContext);
    const {items, selectedBrand, selectedCategory} = itemContext.store;
    const shownItems = React.useMemo(() => items
            ?.filter((item) => (selectedCategory !== "전체")? item?.categories?.indexOf(selectedCategory) >= 0 : true)
            ?.filter((item) => (selectedBrand !== "전체")? item?.seller?.brand === selectedBrand : true)
            ?.slice(0, 20)
        , [items, selectedBrand, selectedCategory])

    return <ListGroup>
        {
            shownItems?.map((item) => <Item item={item} key={item.id}/>)
        }
    </ListGroup>
}