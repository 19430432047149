import React from 'react';
import {
    Container,
    Row,
    Col,
    ListGroup
} from "react-bootstrap";
import ItemContext from "../contexts/ItemContext";
import ARViewer from "../components/ARViewer";
import ItemList from "../components/ItemList";
import ItemFilter from "../components/ItemFilter";


export default function DemoView() {
    const itemContext = React.useContext(ItemContext);
    const {items, selectedItem} = itemContext.store;

    React.useEffect(
        () => {
            itemContext.actions.fetchItems();
            itemContext.actions.fetchCategories();
            itemContext.actions.fetchBrands();
        }
        , []
    )

    return (
        <Container>
            <Row>
                <Col lg={6} className="d-none d-sm-block">
                    <ItemFilter/>
                    <ItemList/>
                </Col>
                <Col xs={12} lg={6}>
                    {
                        selectedItem && <ARViewer src={`/items/${selectedItem?.seller?.id}/${selectedItem?.id}.glb`}/>
                    }
                </Col>

                <Col xs={12} className="d-block d-sm-none">
                    <ItemFilter/>
                    <ItemList/>
                </Col>
            </Row>
        </Container>
    )
}