import React from 'react';
import {
    Form
} from "react-bootstrap";
import ItemContext from "../contexts/ItemContext";


export default function ItemFilter() {
    const itemContext = React.useContext(ItemContext);
    const {categories, brands, selectedCategory, selectedBrand} = itemContext.store;
    const cateList = React.useMemo(() => {
        function dfs(cate) {
            return Object.keys(cate)?.reduce((merged, curr) => [...merged, curr, ...dfs(cate?.[curr])], []) || [];
        }
        return Object.keys(categories)?.reduce((merged, curr) => [...merged, ...dfs(categories?.[curr])], ["전체"]);
    }, [categories])

    return (
        <Form>
            <Form.Group className="mb-3" >
                <Form.Label>카테고리</Form.Label>
                <Form.Select
                    value={selectedCategory}
                    onChange={(e) => {
                        itemContext.actions.setSelectedCategory(e.target.value);
                    }}
                >
                    {
                        cateList.map((cate, idx) => <option key={idx} value={cate}>{cate}</option>)
                    }
                </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" >
                <Form.Label>브랜드</Form.Label>
                <Form.Select
                    value={selectedBrand}
                    onChange={(e) => {
                        itemContext.actions.setSelectedBrand(e.target.value);
                    }}
                >
                    {
                        brands.map((brand, idx) => <option key={idx} value={brand}>{brand}</option>)
                    }
                </Form.Select>
            </Form.Group>
        </Form>
    )
}