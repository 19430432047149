import React, { createContext, useState } from "react";
import * as itemService from "../services/items";


const initialValue = {
    session: null
}
const SessionContext = createContext({});
export default SessionContext;

export class SessionContextProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            store: initialValue,
            actions: {
                setSession: (session => {
                    const today = new Date()
                    window.localStorage.setItem('lastSession', session)
                    window.localStorage.setItem(
                        'sessionExpire',
                        new Date(today.setDate(today.getDate() + 7)).toISOString()
                    )

                    this.setState({...this.state, store: {...this.state.store, session: session}})
                })
            }
        };
    }

    componentDidMount() {
        const expire = window.localStorage.getItem('sessionExpire');
        const lastSession = window.localStorage.getItem('lastSession');
        if (new Date().toISOString() < expire) {
            this.setState({...this.state, store: {...this.state.store, session: lastSession}})
        }
    }

    render() {
        return <SessionContext.Provider value={this.state}>
            {this.props.children}
        </SessionContext.Provider>
    }
}

